<template>
  <div>
    <v-btn icon value="whatsapp" href="https://wa.me/+17807164898" target="_blank">
      <v-icon>mdi-whatsapp</v-icon>
    </v-btn>

    <v-btn icon value="facebook" href="https://www.facebook.com/AgroSavvy-102520421799008/" target="_blank">
      <v-icon>mdi-facebook</v-icon>
    </v-btn>

    <!-- <v-btn icon value="twitter" href="https://twitter.com/uocharcoal/" target="_blank">
      <v-icon>mdi-twitter</v-icon>
    </v-btn> -->
  </div>
</template>

<script>
export default {
  name: "SocialSite"
}
</script>

<template>
  <div>
    <p>
      Understanding the different needs of our customers is very important to us and helps us meet their expectations and personalized requests. In addition to offering the highest quality products, we ensure efficiency in our sourcing, processing, packaging and distribution of our products.
    </p>
    <p>
      AgroSavvy products are very competitive priced.
    </p>
    <p>
      Our clients all over the world can be rest assured that we offer our services and products with our eyes set on excellence. The core values of our business include; excellent service delivery, quality and integrity.
    </p>
  </div>
</template>

<script>
export default {
  name: 'AboutDetailsB',
}
</script>

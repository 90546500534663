<template>
  <div>
    <p>
      AgroSavvy is a Canada based business specializing in the sourcing and export of charcoal and agricultural produce from Africa to buyers around the world, specifically the Americas, Asia, and Europe.
    </p>
    <p>
      We have extensive partnership with farmers and charcoal producers in West Africa and have facilitated deals for our buyers/clients across the globe. We insist on quality products for our buyers and go the extra mile to meet their demands.
    </p>
  </div>
</template>

<script>
export default {
  name: 'AboutDetailsA',
}
</script>
